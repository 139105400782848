<template>
    <div class="z" v-loading="loading">
        <el-card v-for="(notification, index) in notifications" :key="index" style="margin-top: 15px;">
            <div slot="header" class="notification">
                <el-avatar :size="60" :src="notification.circleUrl" class="avatar"></el-avatar>
                <el-tag :type="notification.type" effect="dark" class="custom-tag">{{ notification.tag }}</el-tag>
                <div class="info">
                    <span style="margin-top: 5px;">{{ notification.title }}</span>
                    <span class="time">{{ formatTime(notification.time) }}</span>
                </div>
            </div>
            <div style="color: rgba(0, 0, 0, 0.600);">
                {{ notification.content }}
            </div>
        </el-card>
    </div>
</template>

<script>
import axios from 'axios';
import { getToken } from "@/utils/setToken.js";

export default {
    data() {
        return {
            notifications: [],
            loading: false,
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData(params) {
            this.loading = true;
            const usertoken = getToken('token');
            axios
                .get('https://cf-v1.uapis.cn/api/message.php', {
                    params: { usertoken, ...params },
                })
                .then(response => {
                    this.notifications = response.data;
                })
                .catch(error => {
                    console.error('API请求失败', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatTime(time) {
            const currentTime = new Date();
            const notificationTime = new Date(time);
            const timeDifference = currentTime - notificationTime;
            const minutesDifference = Math.floor(timeDifference / (1000 * 60));

            if (minutesDifference < 1) {
                return '刚刚';
            } else if (minutesDifference < 60) {
                return `${minutesDifference}分钟前`;
            } else {
                const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

                if (hoursDifference < 24) {
                    return `${hoursDifference}小时前`;
                } else {
                    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

                    if (daysDifference === 0) {
                        return '今天';
                    } else if (daysDifference === 1) {
                        return '昨天';
                    } else if (daysDifference < 7) {
                        return `${daysDifference}天前`;
                    } else {
                        // 如果超过七天，则显示具体时间
                        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
                        return notificationTime.toLocaleDateString(undefined, options);
                    }
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.notification {
    display: flex;
    align-items: center;
}

.avatar {
    margin-right: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.custom-tag {
    margin-right: 20px;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.time {
    color: rgba(0, 0, 0, 0.300);
    font-size: 15px;
    margin-top: 5px;
}
</style>